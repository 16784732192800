import React from 'react';
import { Chat } from '@aidbase/chat';

export function ACChatbot() {
  return (
    <div className="fixed bottom-4 left-6">
      <Chat chatbotID="hzvhdKMqDj_TPDrz2pZDI" ticketButtonText="Contact us" />
    </div>
  );
}
